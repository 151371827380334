if (!window.console) console = {log: function() {}};

if ((typeof Shopify) === 'undefined') {
  Shopify = {};
}

Shopify.onCartUpdate = function(cart) {
  if (cart.item_count == 1) {
    jQuery('.cart-link').html('<a class="top-link" href="https://shop.mavenpublishing.nl/cart">Winkelwagen (1)</a>');
  } else if(cart.item_count > 1) {
    jQuery('.cart-link').html('<a class="top-link" href="https://shop.mavenpublishing.nl/cart">Winkelwagen (' + cart.item_count + ')</a>');
  }
};

Shopify.onProductUpdate = function(product) {
  var current_epoch = new Date().getTime();
  var publication_at = Math.floor($('#publication_at').html() * 1000);

  if ($('#shopproduct')) {
    jQuery('#shopproduct').html("'" + product.title + "' is te koop in " + product.variants.length + " varianten <div id=\"variants\"></div>");
    jQuery('#shopproduct').html("<div id=\"variants\"></div>");


    if (publication_at > current_epoch) {
      jQuery('#variants').append('<div class="reserve-title">Pre-order</div>');
    }

    for(var i=0; i<product.variants.length; i++){
      jQuery('#variants').append(`
        <div class="black-button">
          <div class="black-button-after"></div>
          <div class="black-button-main action-koopknop" data-variant-id="${product.variants[i].id}">
            ${product.variants[i].title} ${product.variants[i].price} euro
          </div>
        </div>
        <div class="button-text-after">
          Incl. verzendkosten
        </div>
      `);
    }
  }

  if ($('#buy-all-button-container').length) {
    jQuery('#buy-all-button-container').append(`
      <div class="black-button">
        <div class="black-button-after"></div>
        <div class="black-button-main action-koopknop" data-variant-id="${product.variants[0].id}">
          Alle boeken voor ${product.variants[0].price} euro
        </div>
      </div>
    `);
  }

  //jQuery('#shopproduct').append("Koop nu direct in onze online winkel!");
};

Shopify.getCart = function(callback) {
  jQuery.ajax({url: 'https://shop.mavenpublishing.nl/cart.json', dataType: 'jsonp', success:
    function(cart){
      if (callback !== undefined && (typeof callback) === 'function') {
        callback(cart);
      }
      else {
        Shopify.onCartUpdate(cart);
      }
    }
  });
};

Shopify.getProduct = function(productHandle, callback) {
  jQuery.ajax({url: 'https://shop.mavenpublishing.nl/products/'+productHandle+'.json', dataType: 'jsonp', cache: false, success:
    function(result){
      if ((typeof callback) === 'function') {
        callback(result.product);
      }
      else {
        Shopify.onProductUpdate(result.product);
      }
    }
  });
};

$(document).ready(function() {
  Shopify.getCart();
  var split = document.URL.split("/boeken/");
  if (split.length == 2 && !document.URL.match(/\/boeken\/$/)){
    var boek = split[1].split("/")[0];
    Shopify.getProduct(boek.split("?")[0]);
  }

  if ($('#buy-all-button-container').length) {
    var boek = 'alle-boeken-voor-2157-30-euro'
    Shopify.getProduct(boek);
  }
});
